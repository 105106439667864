/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 5
 */

import { AxiosRequestConfig } from "axios";
import { SwaggerResponse } from "./config";
import { Http } from "./httpRequest";
import {
  GetPillowCategoriesPathQueryParams,
  GetPillowCategoryPathQueryParams,
  GetPillowChapterLiveQueryParams,
  GetPillowPodcastChaptersQueryParams,
  GetPillowRadioPodcastsQueryParams,
  GetPillowRadioSchedulesTodayQueryParams,
  GetPillowRadioHistorySearchQueryParams,
  GetPillowRadioLiveQueryParams,
  GetPillowMetadataHistorySearchQueryParams,
  GetPillowMetadataLiveSearchQueryParams,
  GetPillowSearchQueryParams,
  GetPillowTagsQueryParams,
  DeleteAccountUsersMePresetsQueryParams,
  GetAccountUsersMePresetsQueryParams,
  PostAccountUsersMePresetsQueryParams,
  PutAccountUsersMePresetsQueryParams,
  DeleteAccountUsersMePresetsOfflineQueryParams,
  PostAccountUsersMePresetsOfflineQueryParams,
  AppleIDLoginRequest,
  AddToPlaylistResponse,
  PresetsResponse,
  CountryResponse,
  CategoryResponse,
  Categories,
  FacebookLoginRequest,
  WallItems,
  ChapterProgress,
  ChapterProgressResponse,
  ChapterStreams,
  ChapterProgressUpdateRequest,
  OfflinePreset,
  PodcastChaptersProgressResponse,
  RadioLiveMetadata,
  LoginCodeResponse,
  LoginCodeAcceptRequest,
  SessionResponse,
  Session,
  SessionCreateRequest,
  SessionRefreshRequest,
  Streams,
  User,
  UserCreateRequest,
  UserPasswordChangeRequest,
  UserPasswordResetRequest,
  UserContinuity,
  PodcastChapters,
  RadioHistory,
  RadioPodcasts,
  RadioSchedule,
  SearchResults,
  UserResponse,
  UserRefreshResponse,
  SpotifyConnectRequest,
  EmptyResponse,
  DeezerConnectRequest,
  TargetspoConfig,
  PaymentLinkRequest,
  PaymentLinkResponse,
  ContactFormBody,
  RequestBodyPresetArray,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== "production";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

/**
 * delete presets from user
 */
export const deleteAccountUsersMePresets = (
  requestBody: RequestBodyPresetArray,
  queryParams: DeleteAccountUsersMePresetsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteAccountUsersMePresets.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteAccountUsersMePresets.key = "/account/users/me/presets";

/**
 * set a preset not offline
 */
export const deleteAccountUsersMePresetsOffline = (
  queryParams: DeleteAccountUsersMePresetsOfflineQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteAccountUsersMePresetsOffline.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteAccountUsersMePresetsOffline.key = "/account/users/me/presets/offline";

/**
 * Delete user info.
 */
export const deletePillowUserV2UsersMe = (
    configOverride?:AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  
  return Http.deleteRequest(
    deletePillowUserV2UsersMe.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0,
      configOverride,
    )
  )
}

/** Key is end point string without base url */
deletePillowUserV2UsersMe.key = "/Pillow/user/v2/users/me";


/**
 * Disconnect Deezer user.
 */
export const deletePillowUserV2UsersMeDeezer = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserResponse>> => {
  return Http.deleteRequest(
    deletePillowUserV2UsersMeDeezer.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deletePillowUserV2UsersMeDeezer.key = "/Pillow/user/v2/users/me/deezer";

/**
 * Disconnect Spotify user.
 */
export const deletePillowUserV2UsersMeSpotify = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserResponse>> => {
  return Http.deleteRequest(
    deletePillowUserV2UsersMeSpotify.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deletePillowUserV2UsersMeSpotify.key = "/Pillow/user/v2/users/me/spotify";

/**
 * get continuity
 */
export const getAccountUsersMeContinuity = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserContinuity>> => {
  return Http.getRequest(
    getAccountUsersMeContinuity.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getAccountUsersMeContinuity.key = "/account/users/me/continuity";

/**
 * get user presets
 */
export const getAccountUsersMePresets = (
  queryParams: GetAccountUsersMePresetsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PresetsResponse>> => {
  return Http.getRequest(
    getAccountUsersMePresets.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getAccountUsersMePresets.key = "/account/users/me/presets";

/**
 * get Targetspot config
 */
export const getPillowAdvertisingTargetspot = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TargetspoConfig>> => {
  return Http.getRequest(
    getPillowAdvertisingTargetspot.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowAdvertisingTargetspot.key = "/Pillow/advertising/targetspot";

/**
 * get the root categories
 */
export const getPillowCategories = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Categories>> => {
  return Http.getRequest(
    getPillowCategories.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowCategories.key = "/Pillow/categories";

/**
 * get a path
 */
export const getPillowCategoriesPath = (
  path: string,
  queryParams: GetPillowCategoriesPathQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Categories>> => {
  return Http.getRequest(
    template(getPillowCategoriesPath.key, { path }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowCategoriesPath.key = "/Pillow/categories/{path}";

/**
 * get a path
 */
export const getPillowCategoryPath = (
  categoryPath: string,
  queryParams: GetPillowCategoryPathQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Categories>> => {
  return Http.getRequest(
    template(getPillowCategoryPath.key, { categoryPath }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowCategoryPath.key = "/Pillow/{categoryPath}";

/**
 * get a chapter track
 */
export const getPillowChapterLive = (
  chapter: string,
  queryParams: GetPillowChapterLiveQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowChapterLive.key, { chapter }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowChapterLive.key = "/Pillow/{chapter}/live";

/**
 * Read chapter progress.
 */
export const getPillowListenV2ProgressChapter_id = (
  chapter_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChapterProgressResponse>> => {
  return Http.getRequest(
    template(getPillowListenV2ProgressChapter_id.key, { chapter_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowListenV2ProgressChapter_id.key =
  "/Pillow/listen/v2/progress/{chapter_id}";

/**
 * Read podcast chapters progress.
 */
export const getPillowListenV2ProgressPodcast = (
  podcast: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PodcastChaptersProgressResponse>> => {
  return Http.getRequest(
    template(getPillowListenV2ProgressPodcast.key, { podcast }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowListenV2ProgressPodcast.key = "/Pillow/listen/v2/progress/{podcast}";

/**
 * find metadata in radio history
 */
export const getPillowMetadataHistorySearch = (
  queryParams: GetPillowMetadataHistorySearchQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getPillowMetadataHistorySearch.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowMetadataHistorySearch.key = "/Pillow/metadata/history/search";

/**
 * find metadata live on radios
 */
export const getPillowMetadataLiveSearch = (
  queryParams: GetPillowMetadataLiveSearchQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getPillowMetadataLiveSearch.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowMetadataLiveSearch.key = "/Pillow/metadata/live/search";

/**
 * get a podcast
 */
export const getPillowPodcast = (
  podcast: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CategoryResponse>> => {
  return Http.getRequest(
    template(getPillowPodcast.key, { podcast }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcast.key = "/Pillow/{podcast}";

/**
 * get a podcast chapters
 */
export const getPillowPodcastChapters = (
  podcast: string,
  queryParams: GetPillowPodcastChaptersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PodcastChapters>> => {
  return Http.getRequest(
    template(getPillowPodcastChapters.key, { podcast }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcastChapters.key = "/Pillow/{podcast}/chapters";

/**
 * get a podcast radio
 */
export const getPillowPodcastLatest = (
  podcast: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowPodcastLatest.key, { podcast }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcastLatest.key = "/Pillow/{podcast}/latest";

/**
 * get a podcast radio
 */
export const getPillowPodcastRadio = (
  podcast: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowPodcastRadio.key, { podcast }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcastRadio.key = "/Pillow/{podcast}/radio";

/**
 * get a podcast radio
 */
export const getPillowPodcastSimilar = (
  podcast: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowPodcastSimilar.key, { podcast }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcastSimilar.key = "/Pillow/{podcast}/similar";

/**
 * get a chapter
 */
export const getPillowPodcastchapter = (
  podcastchapter: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowPodcastchapter.key, { podcastchapter }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcastchapter.key = "/Pillow/{podcastchapter}";

/**
 * get a chapter streams
 */
export const getPillowPodcastchapterPlay = (
  podcastchapter: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChapterStreams>> => {
  return Http.getRequest(
    template(getPillowPodcastchapterPlay.key, { podcastchapter }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowPodcastchapterPlay.key = "/Pillow/{podcastchapter}/play";

/**
 * get a radio
 */
export const getPillowRadio = (
  radio: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CategoryResponse>> => {
  return Http.getRequest(
    template(getPillowRadio.key, { radio }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadio.key = "/Pillow/{radio}";

/**
 * Get the last 10 tracks from a given radio
 */
export const getPillowRadioHistory = (
  radio: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RadioHistory>> => {
  return Http.getRequest(
    template(getPillowRadioHistory.key, { radio }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioHistory.key = "/Pillow/{radio}/history";

/**
 * find metadata in radio history
 */
export const getPillowRadioHistorySearch = (
  radio: string,
  queryParams: GetPillowRadioHistorySearchQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowRadioHistorySearch.key, { radio }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioHistorySearch.key = "/Pillow/{radio}/history/search";

/**
 * the current state hash of the radio
 */
export const getPillowRadioLive = (
  radio: string,
  queryParams: GetPillowRadioLiveQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RadioLiveMetadata>> => {
  return Http.getRequest(
    template(getPillowRadioLive.key, { radio }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioLive.key = "/Pillow/{radio}/live";

/**
 * get the streams of a radio
 */
export const getPillowRadioPlay = (
  radio: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Streams>> => {
  return Http.getRequest(
    template(getPillowRadioPlay.key, { radio }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioPlay.key = "/Pillow/{radio}/play";

/**
 * get the podcasts of a radio
 */
export const getPillowRadioPodcasts = (
  radio: string,
  queryParams: GetPillowRadioPodcastsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RadioPodcasts>> => {
  return Http.getRequest(
    template(getPillowRadioPodcasts.key, { radio }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioPodcasts.key = "/Pillow/{radio}/podcasts";

/**
 * get radio's schedule for today
 */
export const getPillowRadioSchedulesToday = (
  radio: string,
  queryParams: GetPillowRadioSchedulesTodayQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<RadioSchedule>> => {
  return Http.getRequest(
    template(getPillowRadioSchedulesToday.key, { radio }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioSchedulesToday.key = "/Pillow/{radio}/schedules/today";

/**
 * get a podcast radio
 */
export const getPillowRadioSimilar = (
  radio: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowRadioSimilar.key, { radio }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowRadioSimilar.key = "/Pillow/{radio}/similar";

/**
 * Search or filter radios and podcasts
 */
export const getPillowSearch = (
  queryParams: GetPillowSearchQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SearchResults>> => {
  return Http.getRequest(
    getPillowSearch.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowSearch.key = "/Pillow/search";

/**
 * Get login code for display in device and check it's status.
 */
export const getPillowSessionV2Login_code = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoginCodeResponse>> => {
  return Http.getRequest(
    getPillowSessionV2Login_code.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowSessionV2Login_code.key = "/Pillow/session/v2/login_code";

/**
 * Session info.
 */
export const getPillowSessionV2SessionInfo = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data: Session }>> => {
  return Http.getRequest(
    getPillowSessionV2SessionInfo.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowSessionV2SessionInfo.key = "/Pillow/session/v2/session/info";

export const getPillowSuggestTypeQuery = (
  type: "all" | "radio" | "podcast",
  query: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowSuggestTypeQuery.key, { type, query }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowSuggestTypeQuery.key = "/Pillow/suggest/{type}/{query}";

/**
 * get all tag of a type
 */
export const getPillowTags = (
  queryParams: GetPillowTagsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getPillowTags.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowTags.key = "/Pillow/tags";

/**
 * get a tag
 */
export const getPillowTagsTag = (
  tag: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getPillowTagsTag.key, { tag }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowTagsTag.key = "/Pillow/tags/{tag}";

/**
 * Read user info.
 */
export const getPillowUserV2UsersMe = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserRefreshResponse>> => {
  return Http.getRequest(
    getPillowUserV2UsersMe.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowUserV2UsersMe.key = "/Pillow/user/v2/users/me";

/**
 * get your IP's country code, WARNING it can be empty string
 */
export const getPillowV2Country = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CountryResponse>> => {
  return Http.getRequest(
    getPillowV2Country.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowV2Country.key = "/Pillow/v2/country";

/**
 * get live wall
 */
export const getPillowWall = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<WallItems>> => {
  return Http.getRequest(
    getPillowWall.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getPillowWall.key = "/Pillow/wall";

/**
 * add presets to user
 */
export const postAccountUsersMePresets = (
  requestBody: RequestBodyPresetArray,
  queryParams: PostAccountUsersMePresetsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAccountUsersMePresets.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountUsersMePresets.key = "/account/users/me/presets";

/**
 * set a preset offline property
 */
export const postAccountUsersMePresetsOffline = (
  requestBody: OfflinePreset,
  queryParams: PostAccountUsersMePresetsOfflineQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAccountUsersMePresetsOffline.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountUsersMePresetsOffline.key = "/account/users/me/presets/offline";

export const postAccountUsersMeSupport = (
  requestBody: ContactFormBody,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAccountUsersMeSupport.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postAccountUsersMeSupport.key = "/account/users/me/support";

/**
 * Create payment link for logged in user.
 */
export const postPillowBillingStripeV2Payment_link = (
  requestBody: PaymentLinkRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<PaymentLinkResponse>> => {
  return Http.postRequest(
    postPillowBillingStripeV2Payment_link.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowBillingStripeV2Payment_link.key =
  "/Pillow/billing/stripe/v2/payment_link";

/**
 * Add to Deezer playlist track currently played in radio.
 */
export const postPillowDeezerV2DeezerTrack_addRadio_id = (
  radio_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AddToPlaylistResponse>> => {
  return Http.postRequest(
    template(postPillowDeezerV2DeezerTrack_addRadio_id.key, { radio_id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowDeezerV2DeezerTrack_addRadio_id.key =
  "/Pillow/deezer/v2/deezer/track_add/{radio_id}";

/**
 * Update chapter progress.
 */
export const postPillowListenV2ProgressChapter_id = (
  chapter_id: string,
  requestBody: ChapterProgressUpdateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ChapterProgress>> => {
  return Http.postRequest(
    template(postPillowListenV2ProgressChapter_id.key, { chapter_id }),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowListenV2ProgressChapter_id.key =
  "/Pillow/listen/v2/progress/{chapter_id}";

export const postPillowSessionSupport = (
  requestBody: ContactFormBody,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postPillowSessionSupport.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionSupport.key = "/Pillow/session/support";

/**
 * Accept login code.
 */
export const postPillowSessionV2Login_codeAccept = (
  requestBody: LoginCodeAcceptRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postPillowSessionV2Login_codeAccept.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionV2Login_codeAccept.key =
  "/Pillow/session/v2/login_code/accept";

/**
 * Create session and obtain access token.
 */
export const postPillowSessionV2SessionCreate = (
  requestBody: SessionCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data: Session }>> => {
  return Http.postRequest(
    postPillowSessionV2SessionCreate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionV2SessionCreate.key = "/Pillow/session/v2/session/create";

/**
 * Login with Apple ID.
 */
export const postPillowSessionV2SessionExternalAppleid = (
  requestBody: AppleIDLoginRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SessionResponse>> => {
  return Http.postRequest(
    postPillowSessionV2SessionExternalAppleid.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionV2SessionExternalAppleid.key =
  "/Pillow/session/v2/session/external/appleid";

/**
 * Login with Facebook.
 */
export const postPillowSessionV2SessionExternalFacebook = (
  requestBody: FacebookLoginRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SessionResponse>> => {
  return Http.postRequest(
    postPillowSessionV2SessionExternalFacebook.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionV2SessionExternalFacebook.key =
  "/Pillow/session/v2/session/external/facebook";

/**
 * Logout session.
 */
export const postPillowSessionV2SessionLogout = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x: string]: any }>> => {
  return Http.postRequest(
    postPillowSessionV2SessionLogout.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionV2SessionLogout.key = "/Pillow/session/v2/session/logout";

/**
 * Session refresh.
 */
export const postPillowSessionV2SessionRefresh = (
  requestBody: SessionRefreshRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ data: Session }>> => {
  return Http.postRequest(
    postPillowSessionV2SessionRefresh.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSessionV2SessionRefresh.key = "/Pillow/session/v2/session/refresh";

/**
 * Add to Spotify playlist track currently played in radio.
 */
export const postPillowSpotifyV2SpotifyTrack_addRadio_id = (
  radio_id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postPillowSpotifyV2SpotifyTrack_addRadio_id.key, { radio_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowSpotifyV2SpotifyTrack_addRadio_id.key =
  "/Pillow/spotify/v2/spotify/track_add/{radio_id}";

/**
 * Connect Deezer user.
 */
export const postPillowUserV2UsersMeDeezer = (
  requestBody: DeezerConnectRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserResponse>> => {
  return Http.postRequest(
    postPillowUserV2UsersMeDeezer.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowUserV2UsersMeDeezer.key = "/Pillow/user/v2/users/me/deezer";

/**
 * Change logged-in user password.
 */
export const postPillowUserV2UsersMePassword = (
  requestBody: UserPasswordChangeRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<EmptyResponse>> => {
  return Http.postRequest(
    postPillowUserV2UsersMePassword.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowUserV2UsersMePassword.key = "/Pillow/user/v2/users/me/password";

/**
 * Connect spotify user.
 */
export const postPillowUserV2UsersMeSpotify = (
  requestBody: SpotifyConnectRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserResponse>> => {
  return Http.postRequest(
    postPillowUserV2UsersMeSpotify.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowUserV2UsersMeSpotify.key = "/Pillow/user/v2/users/me/spotify";

/**
 * Password reset.
 */
export const postPillowUserV2UsersPasswordReset = (
  requestBody: UserPasswordResetRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postPillowUserV2UsersPasswordReset.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowUserV2UsersPasswordReset.key = "/Pillow/user/v2/users/password/reset";

/**
 * Register user.
 */
export const postPillowUserV2UsersRegister = (
  requestBody: UserCreateRequest,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<User>> => {
  return Http.postRequest(
    postPillowUserV2UsersRegister.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postPillowUserV2UsersRegister.key = "/Pillow/user/v2/users/register";

/**
 * set list of presets of the user
 */
export const putAccountUsersMePresets = (
  requestBody: RequestBodyPresetArray,
  queryParams: PutAccountUsersMePresetsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putAccountUsersMePresets.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putAccountUsersMePresets.key = "/account/users/me/presets";
export const _CONSTANT0 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
export const _CONSTANT1 = [{ bearerAuth: [] }];
